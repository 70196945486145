<template>
    <div class="">
        <div class="flex justify-between items-center mb-6">
            <div>
                <h1 class="text-4xl">Transactions</h1>
            </div>

            <div class="flex">
                <div class="search_wrapper">
                    <input class="p-2 border w-80 rounded" v-model="filter" type="search"
                        placeholder="Search by customer or meter number" />
                    <button @click="search()" class="search_btn_icon">
                        <i class="bx bx-search text-2xl"></i>
                    </button>
                </div>
                <button @click="rqFilter()" class="mx-2">
                    <span>
                        <i :class="{ 'text-blue-600': onFilter }" class="bx bx-filter-alt text-2xl"></i>
                    </span>
                </button>
            </div>
        </div>

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-0 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <div v-if="onFilter" class="flex grid-flow-col gap-4 bg-gray-50 p-3">
                            <div class="flex flex-col w-80">
                                <label class="text-gray-600" for="branch">Filter by status
                                </label>
                                <select class="p-2 border rounded" name="branch" @change="loadData(1, filter)"
                                    v-model="filterStatus">
                                    <option value="" disabled selected>
                                        Filter by status
                                    </option>
                                    <option value="INITIATED">INITIATED</option>
                                    <option value="PROCESSING">
                                        PROCESSING
                                    </option>
                                    <option value="PROCESSED">PROCESSED</option>
                                    <option value="FAILED">FAILED</option>

                                    <option value="">All</option>
                                </select>
                            </div>
                            <div class="flex flex-col w-80">
                                <label class="text-gray-600" for="branch">Filter by branch
                                </label>
                                <select class="p-2 border rounded" name="branch" @change="loadData(1, filter)"
                                    v-model="filterBranch">
                                    <option value="" disabled selected>
                                        Filter by branch
                                    </option>
                                    <option value="">All</option>

                                    <option v-for="(branch, i) in branches" :key="i" :value="branch.id">
                                        {{ branch.name }}
                                    </option>
                                </select>
                            </div>

                            <div class="flex flex-col w-80">
                                <label class="text-gray-600" for="branch">Filter by staff
                                </label>
                                <select class="p-2 border rounded" name="branch" @change="loadData(1, filter)"
                                    v-model="filterStaff">
                                    <option value="" disabled selected>
                                        Filter by staff
                                    </option>
                                    <option v-for="(staff, i) in staffs" :key="i" :value="staff.id">
                                        {{ staff.name }}
                                    </option>
                                </select>
                            </div>

                            <div class="flex flex-col w-80">
                                <label class="text-gray-600" for="branch">Filter by date
                                </label>
                                <input class="p-2 border rounded text-xs" type="date" name="date"
                                    @change="loadData(1, filter)" v-model="filterDate" />
                            </div>
                        </div>
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Customer
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Branch
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Staff
                                    </th>

                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Amount/Units
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Date/Time
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Status
                                    </th>

                                    <th scope="col" class="relative px-6 py-3">
                                        <span class="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody v-if="isLoading">
                                <tr class="">
                                    <td colspan="7">
                                        <spinner :show="isLoading" :content="`Loading `" />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else class="bg-white divide-y divide-gray-200">
                                <tr v-for="(
                                        transaction, i
                                    ) in transactions.data" :key="i">
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="flex items-center">
                                            <div class="ml-4">
                                                <div class="text-sm font-medium text-gray-900">
                                                    {{ transaction.customer }}
                                                </div>
                                                <div class="text-sm text-gray-500">
                                                    {{
                        transaction.meter_serial
                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">
                                            {{ transaction.branch }}
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">
                                            {{ transaction.staff }}
                                        </div>
                                    </td>

                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm font-bold text-gray-900">
                                            GMD
                                            {{ formatPrice(transaction.total) }}
                                            / {{ transaction.units }}.kWh
                                        </div>
                                    </td>

                                    <td class="px-6 py-4 w-20 whitespace-nowrap">
                                        <div class="text-sm font-bold text-gray-900">
                                            {{
                        transaction.created
                        | moment(
                            'Do MMM, YYYY, h:mm:ss a'
                        )
                    }}
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <span v-if="transaction.status_val ==
                        'INITIATED'
                        " class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                            INITIATED
                                        </span>
                                        <span v-if="transaction.status_val ==
                        'PROCESSED'
                        " class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                            PROCESSED
                                        </span>
                                        <span v-if="transaction.status_val ==
                        'PROCESSING'
                        " class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                            PROCESSING
                                        </span>
                                        <span v-if="transaction.status_val ==
                        'FAILED'
                        " class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                            FAILED
                                        </span>
                                    </td>

                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button @click="viewTrans(transaction)"
                                            class="text-indigo-600 hover:text-indigo-900">
                                            <span v-if="transaction.status_val !=
                        'PROCESSING'
                        ">View</span>
                                            <span v-else>Retry</span>
                                        </button>

                                        <template v-if="checkPermissionExist(
                        user.data,
                        'cancel-transaction'
                    )
                        ">
                                            <!-- <button v-if="!transaction.status &&
                        transaction.status_val != 'CANCELED'
                        " @click="cancelTrans(transaction)" class="text-red-600 ml-4 hover:text-indigo-900">
                        <span>Cancel</span>
                      </button> -->
                                        </template>
                                    </td>
                                </tr>

                                <!-- More people... -->
                            </tbody>
                        </table>

                        <div class="border-t" v-if="transactions">
                            <pagination :data="transactions" :midSize="2" @clicked="loadData">
                            </pagination>
                        </div>
                    </div>

                    <modal width="50%" height="100%" :adaptive="true" :scrollable="true" :resizable="true"
                        name="example">
                        <div class="flex justify-center items-center my-3">
                            <div class="flex">
                                <div>
                                    <button @click="print()"
                                        class="uppercase text-gray-700 font-bold py-3 px-4 border border-gray-400 rounded-lg hover:bg-gray-100">
                                        <i class="bx bx-printer"></i>

                                        Print
                                    </button>
                                </div>
                            </div>
                        </div>

                        <transaction :data="selectedTransaction" :loading="isLoadingTrans" :errMessage="errMessage" />
                    </modal>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Pagination from '../../components/Pagination';
import FormatPrice from '@/mixins/FormatPrice';
import Transaction from './Transaction';
import { mapGetters } from 'vuex';
import checkPermissionExist from '../../mixins/checkPermissionExist';
import JSPM from '../../../public/JSPrintManager';

export default {
    middleware: 'auth',
    layout: 'default',
    components: {
        Pagination,
        Transaction,
    },

    mixins: [checkPermissionExist, FormatPrice],
    computed: mapGetters({
        user: 'auth/user',
    }),

    data: () => ({
        transactions: [],
        searching: false,
        filter: '',

        print2default: true,
        selectedPrint: null,

        selectedTransaction: null,

        onFilter: false,
        isLoading: false,

        filterBranch: '',
        filterStaff: '',
        filterDate: '',
        filterStatus: '',
        errMessage: '',
        isLoadingTrans: false,

        branches: [],
        staffs: [],
    }),

    created() {
        var _this = this;
        _this.loadData(1);
        if (!this.checkPermissionExist(this.user.data, 'transaction-read')) {
            this.$router.go(-1);
            return false;
        }
    },

    mounted: function () {
        this.onInit();
    },

    methods: {
        rqFilter() {
            this.onFilter = !this.onFilter;

            if (this.onFilter) {
                this.loadBranches();
                this.loadStaffs();
            }
        },

        viewTrans(trans) {
            this.isLoadingTrans = true;
            this.$modal.show('example');

            this.loadTransaction(trans.id);
        },

        async cancelTrans(trans) {
            try {
                await axios.patch(
                    `/chapman/branch/transaction/cancel/${trans.id}`
                );

                this.loadData(1);
            } catch (error) {
                console.log('Error', error.response.data);
            }
        },

        async loadTransaction(id) {
            this.errMessage = '';
            try {
                const { data } = await axios.get(
                    `/chapman/branch/transaction/${id}`
                );

                if (data.status === 'T303') {
                    this.isLoadingTrans = false;
                    this.errMessage = data.message;
                    return false;
                }
                this.selectedTransaction = data.data;
                this.isLoadingTrans = false;

                return true;
            } catch (e) {
                this.isLoadingTrans = false;

                return false;
            }
        },

        async loadData(pageNo, filter) {
            // Submit the form

            if (filter) {
                this.searching = true;
            } else {
                filter = '';
                this.searching = false;
            }

            this.isLoading = true;
            try {
                const { data } = await axios.get(
                    `/chapman/branch/transactions?page=${pageNo}&filter=${filter}&branch_id=${this.filterBranch}&staff_id=${this.filterStaff}&date=${this.filterDate}&status=${this.filterStatus}`
                );

                this.transactions = data;
                this.searching = false;
                this.isLoading = false;

                return true;
            } catch (e) {
                this.searching = false;
                this.isLoading = false;

                return false;
            }
        },

        print: function () {
            const receipt = this.selectedTransaction;

            if (this.selected_printer === '' && !this.print2default) {
                alert('You must select a printer');
                return;
            }

            var clientPrintJob = new JSPM.ClientPrintJob();

            if (this.print2default) {
                clientPrintJob.clientPrinter = new JSPM.DefaultPrinter();
            } else {
                clientPrintJob.clientPrinter = new JSPM.InstalledPrinter(
                    this.selected_printer
                );
            }

            // var day = new Date(receipt.payment_date);
            var dateCreated = moment(receipt.created).format(
                'MMM Do YYYY, h:mm:ss a'
            );

            //Set content to print...
            //Set content to print...
            //Create ESP/POS commands for sample label
            var esc = '\x1B'; //ESC byte in hex notation
            var newLine = '\x0A'; //LF byte in hex notation
            var fullCut = esc + 'm';

            var commands = esc + '@'; //Initializes the printer (ESC @)
            commands += esc + '!' + '\x38'; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
            commands += 'CHAPMAN XPRESS'; //text to print
            commands += newLine;
            commands += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)

            commands += newLine + newLine;

            commands += receipt.branch;
            commands += newLine;
            commands += `Receipt No .: ${receipt.receipt}`;
            commands += newLine + newLine;
            commands += `Meter No .: ${receipt.meter_serial}`;
            commands += newLine;

            commands += esc + '!' + '\x22';
            commands += `${receipt.customer}`;
            commands += esc + '!' + '\x00';

            commands += newLine;
            commands += `${receipt.meter_serial}`;
            commands += newLine + newLine;
            commands += '------------------ Token Number ---------------';
            commands += newLine;
            commands += esc + '1' + '\x01  ';

            // commands += '';
            commands += newLine;
            commands += '********************************************';
            commands += newLine + newLine;
            commands += esc + '!' + '\x30'; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex

            // commands += `${this.formatToken(receipt.list_tokens[0])}`;

            if (receipt.list_tokens && receipt.list_tokens.length > 0) {
                const formattedTokens = receipt.list_tokens.map(token =>
                    this.formatToken(token)
                );
                commands += '\n';
                commands += formattedTokens.join('\n');
            }
            commands += newLine + newLine;
            commands += esc + '!' + '\x00';

            commands += '********************************************';
            commands += newLine + newLine;

            commands += `Amount Paid .:       GMD ${Number(receipt.total)}`;
            commands += newLine;
            commands += `Fee .:               GMD ${receipt.fee}`;
            commands += newLine;
            commands += `Cashpower Amount .:  GMD ${receipt.amount}`;
            commands += newLine;
            commands += `Total Units .:       ${receipt.units}kWh`;
            commands += newLine;

            commands += `Payment Date .:      ${dateCreated}`;

            commands += newLine + newLine;
            commands += esc + '!' + '\x18'; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
            commands += `Operator: ${receipt.staff}`;
            commands += newLine;
            commands += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)

            commands += 'Use Energy Wisely!';
            commands += newLine;

            // commands += esc + '!' + '\x38';

            commands += newLine;
            commands += `Phone: +220 438 3223 - 307 4455 - ${this?.user?.data?.staff?.branch_contact_phone}`;
            commands += newLine + newLine;
            commands += newLine + newLine;

            commands += fullCut;

            clientPrintJob.printerCommands = commands;
            //Send print job to printer!
            clientPrintJob.sendToClient();
        },

        search() {
            var vm = this;
            this.searching = true;
            vm.loadData(1, vm.filter);
        },

        formatDate(date) {
            var day = new Date(date);

            return moment().utc(day).format('Do MMM, YYYY, h:mm:ss a');
        },

        onInit: function () {
            var _this = this;
            JSPM.JSPrintManager.auto_reconnect = true;
            JSPM.JSPrintManager.start();
            JSPM.JSPrintManager.WS.onStatusChanged = function () {
                _this.getPrinters().then(p => {
                    _this.printers = p;
                    _this.$nextTick();
                });
            };
        },

        getPrinters: function () {
            return new Promise((ok, err) => {
                let printers = [];
                if (
                    JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open
                ) {
                    JSPM.JSPrintManager.getPrinters()
                        .then(function (myPrinters) {
                            printers = myPrinters;
                            ok(printers);
                        })
                        .catch(e => err(e));
                } else {
                    ok(printers);
                }
            });
        },

        async loadStaffs() {
            // Submit the form

            try {
                const { data } = await axios.get(`/staff/all`);

                this.staffs = data.data;

                return true;
            } catch (e) {
                this.isLoading = false;
                return false;
            }
        },

        async loadBranches() {
            // Submit the form

            try {
                const { data } = await axios.get(`/get/branches`);

                this.branches = data.data;

                return true;
            } catch (e) {
                this.isLoading = false;
                return false;
            }
        },

        formatToken(token) {
            return token.match(/\d{4}(?=\d{2,3})|\d+/g).join('-');
        },
    },
};
</script>
